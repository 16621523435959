import React, { useState, useRef, useEffect } from 'react';
import { Text as KonvaText, Transformer } from 'react-konva';

const EditableText = ({ textProps, isSelected, onSelect, onChange }) => {
  const shapeRef = useRef(null);
  const trRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(textProps.text);

  // eslint-disable-next-line no-unused-vars
  const [textareaPosition, setTextareaPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (isSelected && trRef.current) {
      shapeRef.current.moveToTop();
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  // Завершаем трансформацию (изменение размера, вращение)
  const handleTransformEnd = () => {
    const node = shapeRef.current;
    onChange({
      ...textProps,
      x: node.x(),
      y: node.y(),
      fontSize: node.fontSize(),
      rotation: node.rotation(),
    });
  };


  const handleDragEnd = () => {
    const node = shapeRef.current;
    onChange({
      ...textProps,
      x: node.x(),
      y: node.y(),
    });
  };

  
  const handleTextDblClick = (e) => {
    setIsEditing(true);
    const stage = e.target.getStage();
    const textPosition = e.target.getClientRect();
    const stageBox = stage.container().getBoundingClientRect();

    setTextareaPosition({
      x: stageBox.left + textPosition.x,
      y: stageBox.top + textPosition.y,
    });

    setTimeout(() => {
      const textarea = document.getElementById('textarea');
      textarea.focus();
    }, 0);
  };

    // eslint-disable-next-line no-unused-vars
  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  // eslint-disable-next-line no-unused-vars
  const handleBlur = () => {
    setIsEditing(false);
    onChange({
      ...textProps,
      text: text,
    });
  };

  return (
    <>
      <KonvaText
        {...textProps}
        ref={shapeRef}
        draggable
        onClick={onSelect}
        onDblClick={handleTextDblClick}
        onDragEnd={handleDragEnd}
        onTransformEnd={handleTransformEnd}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          anchorCornerRadius={8}
          anchorStrokeWidth={2}
          borderStrokeWidth={2}
          borderStroke='#fc9318'
          anchorStroke='#fc9318'
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 30 || newBox.height < 30) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}

      {/* {isEditing &&
        ReactDOM.createPortal(
          <textarea
            id="textarea"
            value={text}
            onChange={handleTextChange}
            onBlur={handleBlur}
            style={{
              position: 'absolute',
              top: `${textareaPosition.y}px`,
              left: `${textareaPosition.x}px`,
              fontSize: `${textProps.fontSize}px`,
              fontFamily: textProps.fontFamily,
              color: textProps.fill,
              background: 'transparent',
              border: 'none',
              outline: 'none',
              resize: 'none',
              transform: `rotate(${textProps.rotation}deg)`,
              padding: '0',
              margin: '0',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          />,
          document.body
        )} */}
    </>
  );
};

export default EditableText;
