import React from 'react';

import FacesIcon from '../buttons_preview/Faces.png';
import BodyIcon from '../buttons_preview/Body.png';
import FaceAccessoriesIcon from '../buttons_preview/Face_accessories.png';
import HandsIcon from '../buttons_preview/Hands.png';
import HandAccessoriesIcon from '../buttons_preview/Hands_accessories.png';
import HairstylesIcon from '../buttons_preview/Hairstyles.png';

const traitNames = [
  { name: "Faces", icon: FacesIcon },
  { name: "Body Parts", icon: BodyIcon },
  { name: "Face accessories", icon: FaceAccessoriesIcon },
  { name: "Hands", icon: HandsIcon },
  { name: "Hand accessories", icon: HandAccessoriesIcon },
  { name: "Hairstyles", icon: HairstylesIcon }
];

const TraitButtons = ({ selectedTrait, onTraitSelect }) => {
  return (
    <div className="traits-names">
      {traitNames.map((trait, index) => (
        <button
          key={index}
          className={`trait-button ${selectedTrait === trait.name ? 'selected' : ''}`}
          onClick={() => onTraitSelect(trait.name)}
        >
          {trait.name}
          <img src={trait.icon} alt={`${trait.name} icon`} className="trait-button-icon" />
        </button>
      ))}
    </div>
  );
};

export default TraitButtons;
