import React, { useRef } from 'react';

const UploadContainer = ({ onChange }) => {
  
  const fileInputRef = useRef(null); 

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); 
    }
  };

  const handleChange = (event) => {
    onChange(event); 
    if (fileInputRef.current) {
      fileInputRef.current.value = null; 
    }
  };

  return (
  <div>
    <button 
        className="action-button" 
        onClick={handleClick}
      >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.66665 10.8282C1.86266 10.29 1.33331 9.37347 1.33331 8.33333C1.33331 6.77095 2.52765 5.48753 4.05314 5.34625C4.36519 3.44809 6.01348 2 7.99998 2C9.98648 2 11.6348 3.44809 11.9468 5.34625C13.4723 5.48753 14.6666 6.77095 14.6666 8.33333C14.6666 9.37347 14.1373 10.29 13.3333 10.8282M5.33331 10.6667L7.99998 8M7.99998 8L10.6666 10.6667M7.99998 8V14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      <span>Upload Bg</span>
    </button>

    <input
      id="fileInput"
      ref={fileInputRef}
      type="file"
      accept="image/*"
      onChange={handleChange}
      className="visually-hidden-input"
    />
  </div>
  );
};

export default UploadContainer;
