import React from 'react';
import './MobileRedirect.css';
import Frog from "./frog.svg"

const MobileRedirect = () => {
  return (
    <div className="mobile-redirect-container">
      <div className="mobile-redirect-content">
        <img src={Frog} alt="Pepe" className="pepe-image" />
        <div className="mobile-redirect-text">
          <div className='header'>Try Pepelin Studio desktop for now!</div>
          <div className='body'>We're working on mobile UX. It's comin' soon ;)</div>
        </div>
      </div>
    </div>
  );
};

export default MobileRedirect;
