const useTraits = () => {

  const traitsData = {
    'Faces': [
      '/traits/face/34_face1.png',
      '/traits/face/34_face2.png',
      '/traits/face/34_face3.png',
      '/traits/face/34_face4.png',
      '/traits/face/34_face5.png',
      '/traits/face/34_face6.png',
      '/traits/face/34_face7.png',
      
      '/traits/face/back_face1.png',
      '/traits/face/back_face2.png',
      '/traits/face/back_face3.png',
      '/traits/face/back_face4.png',
      '/traits/face/back_face5.png',
      '/traits/face/back_face6.png',
      
      '/traits/face/front_face1.png',
      '/traits/face/front_face2.png',
      '/traits/face/front_face3.png',
      '/traits/face/front_face4.png',
      '/traits/face/front_face5.png',
      '/traits/face/front_face6.png',
      '/traits/face/front_face7.png',
      '/traits/face/front_face8.png',

      '/traits/face/profile_face1.png',
      '/traits/face/profile_face2.png',
      '/traits/face/profile_face3.png',
      '/traits/face/profile_face4.png',
      '/traits/face/profile_face5.png',
      '/traits/face/profile_face6.png',
      '/traits/face/profile_face7.png',
      '/traits/face/profile_face8.png',
    ],
    'Body Parts': [
      '/traits/body/body1.png',
      '/traits/body/body2.png',
      '/traits/body/body3.png',
      '/traits/body/body4.png',
    ],
    'Face accessories': [
      '/traits/face_accessories/34_faceaccessories1.png',
      '/traits/face_accessories/34_faceaccessories10.png',
      '/traits/face_accessories/34_faceaccessories11.png',
      '/traits/face_accessories/34_faceaccessories12.png',
      '/traits/face_accessories/34_faceaccessories13.png',
      '/traits/face_accessories/34_faceaccessories14.png',
      '/traits/face_accessories/34_faceaccessories15.png',
      '/traits/face_accessories/34_faceaccessories16.png',
      '/traits/face_accessories/34_faceaccessories17.png',
      '/traits/face_accessories/34_faceaccessories2.png',
      '/traits/face_accessories/34_faceaccessories3.png',
      '/traits/face_accessories/34_faceaccessories5.png',
      '/traits/face_accessories/34_faceaccessories6.png',
      '/traits/face_accessories/34_faceaccessories7.png',
      '/traits/face_accessories/34_faceaccessories8.png',
      '/traits/face_accessories/34_faceaccessories9.png',
      '/traits/face_accessories/back_faceaccessories1.png',
      '/traits/face_accessories/back_faceaccessories10.png',
      '/traits/face_accessories/back_faceaccessories11.png',
      '/traits/face_accessories/back_faceaccessories12.png',
      '/traits/face_accessories/back_faceaccessories13.png',
      '/traits/face_accessories/back_faceaccessories14.png',
      '/traits/face_accessories/back_faceaccessories15.png',
      '/traits/face_accessories/back_faceaccessories16.png',
      '/traits/face_accessories/back_faceaccessories2.png',
      '/traits/face_accessories/back_faceaccessories3.png',
      '/traits/face_accessories/back_faceaccessories4.png',
      '/traits/face_accessories/back_faceaccessories5.png',
      '/traits/face_accessories/back_faceaccessories6.png',
      '/traits/face_accessories/back_faceaccessories7.png',
      '/traits/face_accessories/back_faceaccessories8.png',
      '/traits/face_accessories/back_faceaccessories9.png',
      '/traits/face_accessories/front_faceaccessories1.png',
      '/traits/face_accessories/front_faceaccessories10.png',
      '/traits/face_accessories/front_faceaccessories11.png',
      '/traits/face_accessories/front_faceaccessories12.png',
      '/traits/face_accessories/front_faceaccessories13.png',
      '/traits/face_accessories/front_faceaccessories14.png',
      '/traits/face_accessories/front_faceaccessories15.png',
      '/traits/face_accessories/front_faceaccessories16.png',
      '/traits/face_accessories/front_faceaccessories17.png',
      '/traits/face_accessories/front_faceaccessories18.png',
      '/traits/face_accessories/front_faceaccessories2.png',
      '/traits/face_accessories/front_faceaccessories4.png',
      '/traits/face_accessories/front_faceaccessories5.png',
      '/traits/face_accessories/front_faceaccessories6.png',
      '/traits/face_accessories/front_faceaccessories7.png',
      '/traits/face_accessories/front_faceaccessories8.png',
      '/traits/face_accessories/front_faceaccessories9.png',
      '/traits/face_accessories/profile_faceaccessories1.png',
      '/traits/face_accessories/profile_faceaccessories10.png',
      '/traits/face_accessories/profile_faceaccessories11.png',
      '/traits/face_accessories/profile_faceaccessories12.png',
      '/traits/face_accessories/profile_faceaccessories13.png',
      '/traits/face_accessories/profile_faceaccessories14.png',
      '/traits/face_accessories/profile_faceaccessories15.png',
      '/traits/face_accessories/profile_faceaccessories16.png',
      '/traits/face_accessories/profile_faceaccessories2.png',
      '/traits/face_accessories/profile_faceaccessories3.png',
      '/traits/face_accessories/profile_faceaccessories4.png',
      '/traits/face_accessories/profile_faceaccessories5.png',
      '/traits/face_accessories/profile_faceaccessories6.png',
      '/traits/face_accessories/profile_faceaccessories7.png',
      '/traits/face_accessories/profile_faceaccessories8.png',
      '/traits/face_accessories/profile_faceaccessories9.png'
      
    ],
    'Hands': [
      '/traits/hands/34_hands1.png',
      '/traits/hands/34_hands2.png',
      '/traits/hands/34_hands3.png',
      '/traits/hands/34_hands4.png',
      '/traits/hands/34_hands5.png',
      '/traits/hands/34_hands6.png',
      '/traits/hands/34_hands7.png',
      '/traits/hands/34_hands8.png',
      '/traits/hands/34_hands9.png',
      '/traits/hands/34_hands10.png',
      '/traits/hands/34_hands11.png',
      '/traits/hands/front_hands1.png',
    ],
    'Hand accessories': [
      '/traits/hands_accessories/back_handsaccessories2.png',
      '/traits/hands_accessories/back_handsaccessories3.png',
      '/traits/hands_accessories/back_handsaccessories4.png',
      '/traits/hands_accessories/back_handsaccessories5.png',
      '/traits/hands_accessories/back_handsaccessories6.png',
      '/traits/hands_accessories/back_handsaccessories7.png',
      '/traits/hands_accessories/back_handsaccessories8.png',
      '/traits/hands_accessories/back_handsaccessories9.png',
      '/traits/hands_accessories/back_handsaccessories10.png',
      '/traits/hands_accessories/back_handsaccessories11.png',
      '/traits/hands_accessories/back_handsaccessories12.png',
      '/traits/hands_accessories/back_handsaccessories13.png',

      '/traits/hands_accessories/front_chain1.png',

      '/traits/hands_accessories/front_gloves3.png',
      '/traits/hands_accessories/front_gloves4.png',
      '/traits/hands_accessories/front_gloves6.png',

      '/traits/hands_accessories/front_watch5.png'
    ],
    'Hairstyles': [
      '/traits/hair/34_hairstyles1.png',
      '/traits/hair/34_hairstyles2.png',
      '/traits/hair/34_hairstyles3.png',
      '/traits/hair/34_hairstyles4.png',
      '/traits/hair/34_hairstyles5.png',

      '/traits/hair/back_hairstyles1.png',
      '/traits/hair/back_hairstyles2.png',
      '/traits/hair/back_hairstyles3.png',
      '/traits/hair/back_hairstyles4.png',
      '/traits/hair/back_hairstyles5.png',

      '/traits/hair/front_hairstyles1.png',
      '/traits/hair/front_hairstyles2.png',
      '/traits/hair/front_hairstyles3.png',
      '/traits/hair/front_hairstyles4.png',
      '/traits/hair/front_hairstyles5.png',

      '/traits/hair/profile_hairstyles1.png',
      '/traits/hair/profile_hairstyles2.png',
      '/traits/hair/profile_hairstyles3.png',
      '/traits/hair/profile_hairstyles4.png',
      '/traits/hair/profile_hairstyles5.png',
    ],
  };

  return {
    traitsData
  };
};

export default useTraits;
