import React from 'react';

const ActionButton = ({ name, icon, label, isDisabled, onClick}) => {

    return (
        <div className="kanvas-button-container"> 
        <button className={`action-button ${isDisabled ? 'disabled' : ''}`} disabled={isDisabled} onClick={onClick}>
            {icon}
            <span className="button-text">{name}</span>
        </button>
        <div className="kanvas-button-label">{label}</div>
        </div>
    );
};

export default ActionButton;
