import React, { useState, useEffect } from 'react';

const TraitsFrame = ({ allTraitsData, selectedTrait, onSelectTrait }) => {
  const [loadedImages, setLoadedImages] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadImages = async () => {
      setLoading(true); 
      const imagesCache = { ...loadedImages };

      for (const [traitCategory, traitImages] of Object.entries(allTraitsData)) {
        if (!imagesCache[traitCategory]) {
          const imagePromises = traitImages.map((src) => {
            return new Promise((resolve) => {
              const img = new Image();
              img.src = src;
              img.onload = () => resolve({ src, loaded: true });
              img.onerror = () => resolve({ src, loaded: false });
            });
          });

          const loadedCategoryImages = await Promise.all(imagePromises);
          imagesCache[traitCategory] = loadedCategoryImages.filter(img => img.loaded).map(img => img.src);
        }
      }

      setLoadedImages(imagesCache);
      setLoading(false);
    };

    loadImages();
  }, [allTraitsData]);

  useEffect(() => {
  
    if (!loadedImages[selectedTrait]) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [selectedTrait, loadedImages]);

  const currentImages = loadedImages[selectedTrait] || [];

  return (
    <div className="traits-images-container">
      {loading ? (
        <div className="loading-screen">
          <div className="spinner"></div> 
        </div>
      ) : (
        currentImages.map((imageSrc, index) => (
          <div key={index} onClick={() => onSelectTrait(imageSrc, index)} className="traits-image-container">
            <div className="traits-image-back">
              <img src={imageSrc} alt={`Trait ${index}`} className="traits-image" />
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default TraitsFrame;
