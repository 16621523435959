import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Stage, Layer, Image as KonvaImage} from 'react-konva';
import useImage from 'use-image';
import SelectableImage from './SelectableImage';
import EditableText from './EditableText';

const Kanvas = forwardRef(({ baseImage, traits, texts, onUpdateTrait, onUpdateText}, ref) => {
  const stageRef = useRef(null);
  const maxStageSize = 700;
  const [baseImageDimensions, setBaseImageDimensions] = useState({ width: 0, height: 0 });
  const [selectedTraitId, setSelectedTraitId] = useState(null); 
  const [selectedTextId, setSelectedTextId] = useState(null); 
  const [showCopyNotification, setShowCopyNotification] = useState(false); 
  const [notificationText, setNotificationText] = useState(''); 
  const [notificationStyle, setNotificationStyle] = useState('success'); 
  const [canvasHeight, setCanvasHeight] = useState(''); 
  const [canvasWidth, setCanvasWidth] = useState(''); 


  useImperativeHandle(ref, () => ({
    downloadImage: handleDownload,
    resetCanvas: handleReset,
    copyImageToClipboard: handleCopyToClipboard,
    addText: handleAddText,
    getSelectedElementId: getSelectedElementId
  }));

  useEffect(() => {
    if (window.innerWidth < 768) {
        setCanvasWidth(window.innerWidth*0.98);
        setCanvasHeight(window.innerHeight*0.88);
    } else {
        setCanvasWidth(maxStageSize);
        setCanvasHeight(maxStageSize);
    }
  }, []);


  useEffect(() => {
    if (baseImage) {
      const img = new Image();
      img.onload = () => {
        let { width, height } = img;

        if (width > canvasWidth || height > canvasHeight) {
          const scale = Math.min(canvasWidth / width, canvasHeight / height);
          width = width * scale;
          height = height * scale;
        }

        setBaseImageDimensions({ width, height });
      };
      img.src = baseImage;
    }
  }, [baseImage]);

  const handleReset = () => {
    onUpdateTrait([]);
    onUpdateText([]);
    setBaseImageDimensions({ width: 0, height: 0 });
  };

  const handleDownload = async () => {
    setSelectedTraitId(null);
    const stage = stageRef.current.getStage();
    stage.batchDraw();

    setTimeout(async () => {
        const dataURL = stage.toDataURL({
              pixelRatio: 3,
              mimeType: 'image/png',
              backgroundColor: null, 
        });
        
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);

        if (isMobile && navigator.share) {
            const blob = await fetch(dataURL).then(res => res.blob());
            const file = new File([blob], "image.png", { type: "image/png" });

            try {
                await navigator.share({
                    files: [file],
                    title: 'Meme Image',
                    text: 'Check out this meme!',
                });
                console.log('Image shared successfully');
            } catch (err) {
                console.error('Error sharing image:', err);
            }
        } else {
         
            const link = document.createElement('a');
            link.download = 'meme.png';
            link.href = dataURL;
            link.click();
        }
    }, 50);
};

  const handleCopyToClipboard = async () => {

    setSelectedTraitId(null);
    const stage = stageRef.current.getStage();
    stage.batchDraw();

    setTimeout(async () => {
      const dataURL = stage.toDataURL({
        pixelRatio: window.devicePixelRatio,
        mimeType: 'image/png',
      });
  
      const blob = base64ToBlob(dataURL.split(',')[1], 'image/png');
      const clipboardItem = new ClipboardItem({ 'image/png': blob });
  
      try {
        await navigator.clipboard.write([clipboardItem]);

        setNotificationText('Meme copied to clipboard!');
        setNotificationStyle('success');
        setShowCopyNotification(true);

        setTimeout(() => {
          setShowCopyNotification(false);
        }, 3000);
        
      } catch (err) {
        console.error('Failed to copy: ', err);

        setNotificationText('Failed to copy meme. Try again!');
        setNotificationStyle('error');
        setShowCopyNotification(true);

        setTimeout(() => {
          setShowCopyNotification(false);
        }, 3000);
      }
    }, 50); 
  }

const base64ToBlob = (base64, type) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  
  return new Blob([bytes], { type: type });
};

  const handleAddText = () => {
    const newText = {
      id: `${Date.now()}`,
      text: 'New Text',
      x: 50,
      y: 50,
      fontSize: 25,
      draggable: true,
      fill: '#FFE500',
    };
    onUpdateText([...texts, newText]);
  };

  const getSelectedElementId = () => {
    if (selectedTraitId !== null) {
      return selectedTraitId;
    } else {
      return selectedTextId;
    }
  }

  const handleTraitChange = (id, newAttrs) => {
    const updatedTraits = traits.map((trait) => 
      trait.id === id ? { ...trait, ...newAttrs } : trait
    );
    onUpdateTrait(updatedTraits);
  };
  

  const handleTextChange = (id, newAttrs) => {
    const updatedTexts = texts.map((text) =>
      text.id === id ? { ...text, ...newAttrs } : text
    );
    onUpdateText(updatedTexts); 
  };

  const handleDeselect = (e) => {
    if (e.target === stageRef.current || e.target.getParent() === stageRef.current.getLayers()[0]) {
      setSelectedTraitId(null);
      setSelectedTextId(null);
    }
  };

  return (
    <div>
        <div>
            <Stage
              width={canvasWidth}
              height={canvasHeight}
              ref={stageRef}
              onMouseDown={handleDeselect}
            >
            <Layer>
              {baseImage && (
                <URLImage src={baseImage} dimensions={baseImageDimensions} sizeWidth={canvasWidth} sizeHeight={canvasHeight}/>
              )}
          
              {traits.map((trait) => (
                <SelectableImage
                  key={trait.id}
                  imageProps={trait}
                  isSelected={trait.id === selectedTraitId} 
                  onSelect={() => {
                    setSelectedTraitId(trait.id)
                    setSelectedTextId(null); 
                  }}
                  onChange={(newAttrs) => handleTraitChange(trait.id, newAttrs)}
              />
              ))}

              {texts.map((text) => (
                <EditableText
                  key={text.id}
                  textProps={text}
                  isSelected={text.id === selectedTextId}
                  onSelect={() => {
                    setSelectedTextId(text.id)
                    setSelectedTraitId(null); 
                   }}
                  onChange={(newAttrs) => handleTextChange(text.id, newAttrs)}
                  />
                ))}

            </Layer>
          </Stage>
        </div>
      {showCopyNotification && (
        <div className={`copy-notification-${notificationStyle}`}>
          <p>{notificationText}</p>
        </div>
      )}
    </div>
  );
});

const URLImage = ({ src, dimensions, sizeWidth, sizeHeight }) => {
  const [image] = useImage(src);
  const imageRef = useRef();

  if (!image) return null;

  const { width, height } = dimensions;

  return (
    <KonvaImage
      image={image}
      x={(sizeWidth - width) / 2} 
      y={(sizeHeight - height) / 2} 
      width={width}
      height={height}
      ref={imageRef}
    />
  );
};

export default Kanvas;
