import React, { useRef, useEffect } from 'react';
import { Image as KonvaImage, Transformer } from 'react-konva';
import useImage from 'use-image';

const SelectableImage = ({ imageProps, isSelected, onSelect, onChange }) => {
  const [image] = useImage(imageProps.src);
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      shapeRef.current.moveToTop();
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();

      const transformer = trRef.current;
      transformer.anchorStyleFunc((anchor) => {
        anchor.cornerRadius(10);
        if (anchor.hasName('top-center') || anchor.hasName('bottom-center')) {
          anchor.height(6);
          anchor.offsetY(3);
          anchor.width(30);
          anchor.offsetX(15);
        }
        if (anchor.hasName('middle-left') || anchor.hasName('middle-right')) {
          anchor.height(30);
          anchor.offsetY(15);
          anchor.width(6);
          anchor.offsetX(3);
        }
      });

      transformer.nodes([shapeRef.current]);
      transformer.getLayer().batchDraw();
    }
  }, [isSelected]);

  const handleTransformEnd = () => {
    const node = shapeRef.current;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    const newWidth = Math.max(50, node.width() * scaleX);
    const newHeight = Math.max(50, node.height() * scaleY);

    onChange({
      ...imageProps,
      x: node.x(),
      y: node.y(),
      width: newWidth,
      height: newHeight,
      rotation: node.rotation(),
    });
  };


  const handleDragEnd = () => {
    const node = shapeRef.current;
    onChange({
      ...imageProps,
      x: node.x(),
      y: node.y(),
    });
  };


  const handleDragMove = () => {
    onSelect();
    if (trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  };


  if (!image) return null;

  return (
    <>
      <KonvaImage
        image={image}
        x={imageProps.x}
        y={imageProps.y}
        width={imageProps.width}
        height={imageProps.height}
        draggable
        rotation={imageProps.rotation}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        onDragMove={handleDragMove} 
        onDragEnd={handleDragEnd}
        onTransformEnd={handleTransformEnd}
      />

      {isSelected && (
        <Transformer
          anchorCornerRadius={8}
          anchorStrokeWidth={2}
          borderStrokeWidth={2}
          borderStroke='#fc9318'
          anchorStroke='#fc9318'
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 50 || newBox.height < 50) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default SelectableImage;
