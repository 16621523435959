import React, { useState } from 'react';
import './Select.css';

// Имена характеристик
const traitNames = [
  { name: "Faces" },
  { name: "Body Parts" },
  { name: "Face accessories" },
  { name: "Hands" },
  { name: "Hand accessories" },
  { name: "Hairstyles" }
];

const TraitSelect = ({ selectedTrait, onTraitSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTraitSelect = (trait) => {
    onTraitSelect(trait);
    setIsOpen(false);
  };

  return (
    <div className="trait-select-container">
      <div className="trait-select" onClick={() => setIsOpen(!isOpen)}>
        <span>{selectedTrait}</span>
        <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>
      </div>
      {isOpen && (
        <div className="trait-options">
          {traitNames.map((trait, index) => (
            <div 
              key={index} 
              className="trait-option" 
              onClick={() => handleTraitSelect(trait.name)}
            >
              {trait.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TraitSelect;
