import './App.css';

import React, { useState, useEffect, useRef,} from 'react';
import { v4 as uuidv4 } from 'uuid';

import Kanvas from './components/Kanvas';
import TraitsFrame from './components/TraitsFrame';
import FileUploadButton from './components/FileUploadButton';
import TraitButtons from './components/TraitButtons';
import useTraits from './components/hooks/UseTraits';
import ActionButton from './components/ActionButton';

import Icons from './components/Icons';
import TraitsLogo from './pepelinstudio.svg';

import MobileRedirect from './components/pages/MobileRedirect/MobileRedirect';
import Modal from './components/modal/Modal';
import TraitSelect from './components/select/Select';

function App() {

  const {traitsData} = useTraits();

  const canvasRef = useRef(null);
  const [baseImage, setBaseImage] = useState(null);
  const [addedTraits, setAddedTraits] = useState([]);
  const [addedTexts, setAddedTexts] = useState([]);
  const [selectedTraitName, setSelectedTraitName] = useState('Faces');

  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /*useEffect(() => {
    const userAgent = navigator.userAgent;
      if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
        setIsMobile(true);
      }
   }, []);*/

  const handleTraitSelect = (imageSrc) => {
      setIsModalOpen(false);

      const img = new Image();
      img.src = imageSrc;
  
      img.onload = () => {
        const { width: originalWidth, height: originalHeight } = img;
        const aspectRatio = originalWidth / originalHeight;
        const baseSize = 100;
        let width, height;  
      
        if (aspectRatio > 1) {
          width = baseSize;
          height = baseSize / aspectRatio;
        } else {

          width = baseSize * aspectRatio;
          height = baseSize;
        }
  
        const id = uuidv4(); 
        setAddedTraits((prevTraits) => [
          ...prevTraits,
          { src: imageSrc, id, x: 50, y: 50, width, height }
        ]);
      };
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setBaseImage(url);
      // setAddedTraits([]);
    }
  };

  const handleFilePaste = async (e) => {
    e.preventDefault();
  
    try {
      if (!navigator.clipboard || !navigator.clipboard.read) {
        console.error("Clipboard is not available");
        return;
      }
  
      const clipboardItems = await navigator.clipboard.read();
      for (const clipboardItem of clipboardItems) {
    
        const imageType = clipboardItem.types.find(type => type.startsWith('image/'));
  
        if (imageType) {
          const blob = await clipboardItem.getType(imageType);
          const url = URL.createObjectURL(blob); 
          setBaseImage(url); 
          setAddedTraits([]); 
          break; 
        }
      }
    } catch (err) {
      console.error("Failed to read clipboard: ", err);
    }
  };  
  
  // const handleButtonPasteClick = () => {
  //   const event = new ClipboardEvent('paste');
  //   window.dispatchEvent(event); 
  // };

  
  // useEffect(() => {
  //   window.addEventListener('paste', handleFilePaste);
  //   return () => window.removeEventListener('paste', handleFilePaste);
  // }, []);
  

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        handleFilePaste(e);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);  
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
        e.preventDefault();
        if (baseImage && canvasRef.current) {
          canvasRef.current.copyImageToClipboard();
        }
      }

      if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        handleFilePaste(e); 
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [baseImage]);


  const handleKanvasReset = () => {
    if (canvasRef.current) {
      canvasRef.current.resetCanvas(); 
      setBaseImage(null);
      setAddedTraits([]);
    }
  };

  const handleDownload = () => {
    if (canvasRef.current) {
      canvasRef.current.downloadImage(); 
    }
  };

  const handleCopyToClipboard = () => {
    if (canvasRef.current) {
      canvasRef.current.copyImageToClipboard(); 
    }
  };

  const handleTraitButtonClick = (traitName) => {
    setSelectedTraitName(traitName);
    console.log("Выбранная характеристика:", traitName);
  };

  const handleTraitChange = (id, newProps) => {
    setAddedTraits((prevTraits) =>
      prevTraits.map((trait) =>
        trait.id === id ? { ...trait, ...newProps } : trait
      )
    );
  };

  const handleTextChange = (updatedTexts) => {
    setAddedTexts(updatedTexts);
  };

  const handleAddText = () => {
    if (canvasRef.current) {
      canvasRef.current.addText(); 
    }
  };

  const handleRemoveElement = () => {
    if (canvasRef.current) {
      const selectedElementId = canvasRef.current.getSelectedElementId();
     
      if (selectedElementId !== null) {
        const updatedTexts = addedTexts.filter((text) => text.id !== selectedElementId);
        const updatedTraits = addedTraits.filter((trait) => trait.id !== selectedElementId);
        setAddedTexts(updatedTexts);
        setAddedTraits(updatedTraits);
      }
    }
  };  

  if (isMobile) {
    return  <div className="App"><MobileRedirect /></div>;
  }
  
  return (
    <div className="App">  

    <Modal show={isModalOpen}>
        <div className="modal-header">
            <TraitSelect
             selectedTrait={selectedTraitName}
             onTraitSelect={handleTraitButtonClick}
            />
            <div className="modal-close-button" onClick={() => setIsModalOpen(false)}>
              Close &times;
           </div>
        </div>

        <div className="modal-body">
          <TraitsFrame
            allTraitsData={traitsData}
            selectedTrait={selectedTraitName}
            onSelectTrait={handleTraitSelect}
          />
        </div>
    </Modal>

      <div className="traits-sidebar">
          <div className="glow-effect"/>

          <div className="traits-sidebar-logo">
             <img src={TraitsLogo} alt="logo" className="adaptive-logo-image"/>
          </div>

          <TraitButtons
            selectedTrait={selectedTraitName}
            onTraitSelect={handleTraitButtonClick}
          />

          <TraitsFrame
            allTraitsData={traitsData}
            selectedTrait={selectedTraitName}
            onSelectTrait={handleTraitSelect}
          />

      </div>
        
        <div className="kanvas-container">
         <div className="kanvas-back">
            <div className="kanvas">
                <Kanvas 
                  baseImage={baseImage}
                  traits={addedTraits} 
                  texts={addedTexts} 
                  onUpdateTrait={handleTraitChange}
                  onUpdateText={handleTextChange} 
                  ref={canvasRef} /> 
            </div>
        </div>

          <div className="kanvas-button-group">
            {
            window.innerWidth < 768 &&  
            <ActionButton
                  icon={Icons.elements}
                  onClick={() => setIsModalOpen(true)}
              />
            }

              <ActionButton
                  name={"Remove"}
                  icon={Icons.remove}
                  isDisabled={addedTraits.length === 0 && addedTexts.length === 0} 
                  onClick={handleRemoveElement}
              />

              <ActionButton
                  name={"Copy Meme"}
                  icon={Icons.copy}
                  label={"Ctrl (Cmd) + C"}
                  isDisabled={!baseImage && addedTraits.length === 0 && addedTexts.length === 0} 
                  onClick={handleCopyToClipboard}
              />      

              {/* <ActionButton
                  name={"Paste"}
                  icon={Icons.paste}
                  label={"Ctrl (Cmd) + V"}
                  onClick={handleButtonPasteClick}
              />     */}

              <ActionButton
                  name={"Reset"}
                  icon={Icons.reset}
                  isDisabled={!baseImage && addedTraits.length === 0  && addedTexts.length === 0} 
                  onClick={handleKanvasReset} 
              />   
              
              <FileUploadButton       
                  onChange={handleFileUpload} 
              />

              {/* <ActionButton
                  name={"Text"}
                  icon={Icons.text}
                  onClick={handleAddText} 
                  isDisabled={true}
              />    */}

              <div className="kanvas-button-container">
                  <button 
                    className={`download-button ${baseImage ? 'disabled' : ''}`} 
                    disabled={!baseImage && addedTraits.length === 0 && addedTexts.length === 0} 
                    onClick={handleDownload}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                          <path d="M5.33333 8.49998L8 11.1666M8 11.1666L10.6667 8.49998M8 11.1666V5.83331M14.6667 8.49998C14.6667 12.1819 11.6819 15.1666 8 15.1666C4.3181 15.1666 1.33333 12.1819 1.33333 8.49998C1.33333 4.81808 4.3181 1.83331 8 1.83331C11.6819 1.83331 14.6667 4.81808 14.6667 8.49998Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <span>Download</span>
                  </button>
              </div>
            </div>  
        </div>
    </div>
  );
}

export default App;
